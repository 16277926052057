import React from 'react';
import { Navigate } from 'react-router-dom';
import { lazyImport } from '../utils/lazyImport';

const { SearchRoutes } = lazyImport(
	() => import('../features/search'),
	'SearchRoutes'
);

const { HomeRoutes } = lazyImport(
	() => import('../features/home'),
	'HomeRoutes'
);

const { ChatRoutes } = lazyImport(
	() => import('../features/chat'),
	'ChatRoutes'
);

const { ResourceRoutes } = lazyImport(
	() => import('../features/resource'),
	'ResourceRoutes'
);

const { StaticRoutes } = lazyImport(
	() => import('../features/static'),
	'StaticRoutes'
);

const { AnalyticsRoutes } = lazyImport(
	() => import('../features/analytics'),
	'AnalyticsRoutes'
);

export const publicRoutes = [
	{
		path: 'analytics/*',
		element: <AnalyticsRoutes />,
	},
	{
		path: '/*',
		element: <StaticRoutes />,
	},
	{
		path: 'resource/*',
		element: <ResourceRoutes />,
	},
	{
		path: 'search/*',
		element: <SearchRoutes />,
	},
	{
		path: 'chat/*',
		element: <ChatRoutes />,
	},
	{
		path: 'home/*',
		element: <HomeRoutes />,
	},
	{
		path: '/',
		element: <Navigate replace to="/home" />,
	},
];
