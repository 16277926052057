/* eslint-disable no-void */
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../assets/GARDIAN-LOGO-MAIN-HEADER.png';

const Logo = () => {
	return (
		<NavLink to="/home">
			<img className="mb-auto mr-7 h-10 w-10" src={logo} alt="" />
		</NavLink>
	);
};

export const NavBar = () => {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
	const navigate = useNavigate();

	const items: MenuItem[] = [
		{
			label: 'Home',
			command: () => {
				navigate('/home');
			},
		},
		{
			label: 'Ask CiGi',
			command: () => {
				navigate('/chat');
			},
		},
		{
			label: 'Search',
			command: () => {
				navigate('/search');
			},
		},
		{
			label: 'Maps',
			command: () => {
				window.location.href = `${window.location.origin}/maps`;
			},
		},
		{
			label: 'Tools',
			command: () => {
				navigate('/tools');
			},
		},
		{
			label: 'Analytics',
			command: () => {
				navigate('/analytics');
			},
		},
		{
			label: 'API',
			command: () => {
				navigate('/api');
			},
		},
		{
			label: 'About',
			command: () => {
				navigate('/about');
			},
		},
		{
			label: `${isAuthenticated ? 'Logout' : 'Login'}`,
			className: 'ml-auto',
			command: () => {
				if (isAuthenticated) {
					void logout({
						logoutParams: {
							returnTo: `${window.location.origin}/home`,
						},
					});
				} else {
					void loginWithRedirect();
				}
			},
		},
	];

	return (
		<Menubar
			model={items}
			start={<Logo />}
			pt={{
				popupIcon: () => ({
					style: { display: 'none' },
				}),
				root: () => ({
					style: {
						background: 'unset',
						border: 'unset',
						margin: 'unset',
					},
					className: 'bg-transparent border-transparent pt-0.5 !p-0',
				}),
				menu: () => ({
					style: { width: '100%', padding: 'unset' },
				}),
				label: () => ({
					style: { color: 'white', border: 'unset' },
				}),
				action: () => ({
					style: { borderRadius: '0px', border: 'unset' },
				}),
				menuitem: () => ({
					className:
						'rounded-none border-solid border-b-4 border-transparent hover:border-gardian-lightblue hover:bg-transparent',
				}),
			}}
		/>
	);
};

type InfoBarProps = {
	title: string;
	hasIcon?: boolean;
};

const getInfoBarProps = (path: string): InfoBarProps => {
	if (path.startsWith('/home')) {
		return {
			title: 'Global Agricultural Research Data Innovation Acceleration Network',
			hasIcon: false,
		};
	}
	if (path.startsWith('/search')) {
		return { title: 'Search', hasIcon: true };
	}
	if (path.startsWith('/resource')) {
		return { title: 'Resource', hasIcon: true };
	}
	if (path.startsWith('/chat')) {
		return { title: 'Ask CiGi', hasIcon: true };
	}
	if (path.startsWith('/analytics')) {
		return { title: 'Analytics', hasIcon: true };
	}
	if (path.startsWith('/fair-metrics')) {
		return { title: 'FAIR Metrics', hasIcon: true };
	}
	if (path.startsWith('/api')) {
		return { title: 'API', hasIcon: true };
	}
	if (path.startsWith('/tools')) {
		return { title: 'Tools', hasIcon: true };
	}
	if (path.startsWith('/about')) {
		return { title: 'About', hasIcon: true };
	}
	return { title: '', hasIcon: false };
};

export const InfoBar = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const infoBarProps = getInfoBarProps(pathname);

	return (
		<span className="flex h-14 flex-row items-center">
			{infoBarProps.hasIcon ? (
				<div className="flex flex-row items-center p-3">
					<Button
						className="p-0"
						text
						onClick={() => {
							navigate('/home');
						}}
					>
						<i
							className={
								'fa-regular fa-house m-1 text-base text-white'
							}
						/>
					</Button>
					<i
						className={
							'fa-regular fa-chevron-right p-1 text-base text-white'
						}
					/>
				</div>
			) : null}
			<p
				className={
					infoBarProps.hasIcon ? 'text-gardian-orange' : 'text-white'
				}
			>
				{infoBarProps.title}
			</p>
		</span>
	);
};

export const Header = () => {
	return (
		<div className="bg-gardian-darkblue bg-gradient-to-r from-gardian-darkblue to-gardian-midblue px-28 py-0">
			<div>
				<NavBar />
			</div>
			<div className="-mt-[8-px] h-px w-full bg-white" />
			<InfoBar />
		</div>
	);
};
